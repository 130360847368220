import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./Components/Pages/Login";
import WaitingScreen from "./Components/Pages/WaitingScreen";
import AppContainer from "./Components/Containers/AppContainer";
import Home from "./Components/Pages/Home";
export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        {!sessionStorage.laboratory && <Redirect push to="/login" />}
        <Route path="/waitingScreen">
          <WaitingScreen />
        </Route>
        <AppContainer>
          <Route path="/home">
            <Home />
          </Route>
        </AppContainer>
      </Switch>
    </Router>
  );
}
