import React, { useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { CssBaseline, Grid, Typography, makeStyles } from "@material-ui/core";
import { useFirebase } from "../../Firebase/FirebaseContext";
import {
  doc,
  onSnapshot,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "@firebase/storage";
import { CreditCard, Description } from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  customer: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
  },
  section: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconHolder: {
    width: 500,
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: "-150px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  iconHolderVideo: {
    // width: 500,
    display: "flex",
    justifyContent: "space-around",
    // position: "absolute",
    // bottom: "-10px",
    // left: "50%",
    // transform: "translateX(-50%)",
  },
  icon: {
    fontSize: "7rem",
    color: theme.palette.primary.main,
  },
  iconVideo: {
    fontSize: "3rem",
    color: theme.palette.primary.main,
  },
  image: {
    margin: 10,
    width: 500,
    height: 500,
    borderRadius: "50%",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
    // border: `1px solid ${theme.palette.primary.main}`,
  },

  technician: {
    textAlign: "center",
    zIndex: 1,
    position: "absolute",
    bottom: "-20px",
    left: "30%",
    width: "100%",
    backgroundColor: "rgba(41, 121, 255,0.8)",
    color: "#fff",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
  waves: {
    position: "relative",
    width: "100%",
    height: "45vh",
    marginBottom: "-7px",
    minHeight: "25vh",
    maxHeight: "28vh",
    zIndex: -10,
  },
  parallax: {
    "& > use": {
      animation: "$move-forever 25s cubic-bezier(.550,.5,.45,.5) infinite",
    },
    "& > use:nth-child(1)": {
      animationDelay: "-3s",
      animationDuration: "21s",
    },
    "& > use:nth-child(2)": {
      animationDelay: "-4s",
      animationDuration: "30s",
    },
    "& > use:nth-child(3)": {
      animationDelay: "-5s",
      animationDuration: "39s",
    },
    "& > use:nth-child(4)": {
      animationDelay: "-6s",
      animationDuration: "60s",
    },
  },
  "@keyframes move-forever": {
    "0%": {
      transform: "translate3d(-90px,0,0)",
    },
    "100%": {
      transform: "translate3d(85px,0,0)",
    },
  },
  video: {
    width: "70%",
    // height: "70%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    // backgroundColor: "#000",
  },
  customerInfoVideo: {
    position: "absolute",
    top: "5%",
    left: "5%",
    zIndex: 10,
    backgroundColor: "#fff",
    padding: 10,
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.3)",
  },
  technicianInfoVideo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    bottom: "5%",
    right: "3%",
    zIndex: 10,
    backgroundColor: "#fff",
    padding: 10,
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.3)",
  },
  imageVideo: {
    margin: 10,
    width: 150,
    height: 150,
    borderRadius: "50%",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
    // border: `1px solid ${theme.palette.primary.main}`,
  },

  technicianVideo: {
    textAlign: "center",
    zIndex: 1,
    position: "absolute",
    bottom: "-20px",
    left: "30%",
    width: "100%",
    backgroundColor: "rgba(41, 121, 255,0.8)",
    color: "#fff",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
}));

export default function WaitingScreen() {
  const { firebase } = useFirebase();
  const classes = useStyles();
  const location = useLocation();
  const locationData = location.state.data;
  const [technician, setTechnician] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [technicianImg, setTechnicianImg] = useState(false);
  const [video, setVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);
  const playerRef = useRef();
  // Constant Data fetching
  const roomRef = doc(firebase.db, "rooms", locationData.id);
  let unsubscribe = () => {};

  let currentVideo = false;
  const fetch = () => {
    onSnapshot(roomRef, (roomData) => {
      const empty = roomData.data().appointementId === "";
      if (!empty) {
        const appointmentRef = doc(
          firebase.db,
          "appointements",
          roomData.data().appointementId
        );
        unsubscribe = onSnapshot(
          appointmentRef,
          async (appointment) => {
            const ended = appointment.data()?.ended_at;
            const technician = appointment.data()?.technician;
            const started = appointment.data()?.started_at;
            const patientFirstName = appointment.data()?.name
              ? appointment.data().name
              : "";
            const patientLastName = appointment.data()?.lastName
              ? appointment.data().lastName
              : "";
            const sex = appointment.data()?.sex;
            const age = appointment.data()?.birthday
              ? moment().diff(
                  moment(appointment.data().birthday, "DD/MM/YYYY"),
                  "years"
                )
              : false;
            if (ended) {
              // console.log("ended");
              setTechnician(false);
              setTechnicianImg(false);
              setFirstName(false);
              setLastName(false);
              setVideo(false);
              setVideoUrl(false);
              unsubscribe();
              currentVideo = false;
              return;
            } else if (technician) {
              const userRef = doc(
                firebase.db,
                "users",
                appointment.data().technician_email
              );
              getDoc(userRef).then((technician) => {
                if (technician.data().profile_picture) {
                  const imgRef = ref(
                    getStorage(),
                    technician.data().profile_picture
                  );
                  getDownloadURL(ref(getStorage(), imgRef))
                    .then((imgUrl) => {
                      setTechnicianImg(imgUrl);
                    })
                    .catch((e) => console.log(e));
                }
              });
              const nameSplit = appointment.data().technician.split(" ");
              setTechnician(nameSplit[0]);
              setFirstName(patientFirstName);
              setLastName(patientLastName);
            } else if (started) {
              if (sex && age && !currentVideo) {
                const videoRef = collection(
                  firebase.db,
                  "laboratories/" + sessionStorage.laboratory + "/videos"
                );
               // const videoRef = collection(firebase.db, "videos");
                const q = query(videoRef, where("sex", "==", sex));
                getDocs(q)
                  .then((queryResult) => {
                    queryResult.forEach((queryVideo) => {
                      const { path } = queryVideo.data();
                      if (
                        queryVideo.data().minAge <= age &&
                        queryVideo.data().maxAge >= age
                      ) {
                        if (currentVideo !== path) {
                          fetchVideo(path);
                          currentVideo = path;
                        }
                      }
                    });
                  })
                  .catch((e) => console.log(e));
              }
              setFirstName(patientFirstName);
              setLastName(patientLastName);
            }
          },
          (e) => console.log(e)
        );
      } else {
        setTechnician(false);
        setTechnicianImg(false);
        setFirstName(false);
        setLastName(false);
        setVideo(false);
        setVideoUrl(false);
      }
    });
  };
  /*   const fetch = () => {
    onSnapshot(roomRef, (roomData) => {
      const empty = roomData.data().appointementId === "";
      if (!empty) {
        const appointmentRef = doc(
          firebase.db,
          "appointements",
          roomData.data().appointementId
        );
        unsubscribe = onSnapshot(appointmentRef, async (appointment) => {
          try {
            const ended = appointment.data()?.ended_at;
            const technician = appointment.data()?.technician;
            const started = appointment.data()?.started_at;
            const patientFirstName = appointment.data()?.name
              ? appointment.data().name
              : "";
            const patientLastName = appointment.data()?.lastName
              ? appointment.data().lastName
              : "";
            const sex = appointment.data()?.sex;
            const age = appointment.data()?.birthday
              ? moment().diff(
                  moment(appointment.data().birthday, "DD/MM/YYYY"),
                  "years"
                )
              : false;
            if (ended) {
              console.log("ended");
              setTechnician(false);
              setTechnicianImg(false);
              setFirstName(false);
              setLastName(false);
              setVideo(false);
              setVideoUrl(false);
              unsubscribe();
              return;
            }
            if (sex && age) {
              console.log("fetch video");
              const videoRef = collection(firebase.db, "videos");
              const q = query(videoRef, where("sex", "==", sex));
              const queryResult = await getDocs(q);

              queryResult.forEach(async (queryVideo) => {
                if (
                  queryVideo.data().minAge <= age &&
                  queryVideo.data().maxAge >= age
                ) {
                  try {
                    if (video !== queryVideo.data().path) {
                      const storage = getStorage();
                      const url = await getDownloadURL(
                        ref(storage, queryVideo.data().path)
                      );
                      setVideo(queryVideo.data().path);
                      setVideoUrl(url);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }
              });
            }
            if (technician) {
              console.log("fetch technician");
              const userRef = doc(
                firebase.db,
                "users",
                appointment.data().technician_email
              );
              const technician = await getDoc(userRef);
              if (technician.data().profile_picture) {
                const imgRef = ref(
                  getStorage(),
                  technician.data().profile_picture
                );
                const imgUrl = await getDownloadURL(ref(getStorage(), imgRef));
                setTechnicianImg(imgUrl);
              }

              const nameSplit = appointment.data().technician.split(" ");
              setTechnician(nameSplit[0]);
            }
            if (started) {
              setFirstName(patientFirstName);
              setLastName(patientLastName);
            }
          } catch (e) {
            console.log(e);
          }
        });
      }
      // else {
      //   setTechnician(false);
      //   setTechnicianImg(false);
      //   setFirstName(false);
      //   setLastName(false);
      //   setVideo(false);
      //   setVideoUrl(false);
      // }
    });
  }; */

  useEffect(() => {
    if (
      !technician &&
      !technicianImg &&
      !firstName &&
      !lastName &&
      !video &&
      !videoUrl
    ) {
      unsubscribe();
    }
  }, [technician, technicianImg, firstName, lastName, video, videoUrl]);
  fetch();

  const fetchVideo = (path) => {
    const storage = getStorage();
    getDownloadURL(ref(storage, path))
      .then((url) => {
        setVideo(path);
        setVideoUrl(url);
      })
      .catch((e) => console.log(e));
  };
  setInterval(() => {
    fetch();
  }, 30000);

  return (
    <Grid
      container
      component="main"
      style={{ overflow: "hidden" }}
      className={classes.root}
    >
      {sessionStorage.username && sessionStorage.role && (
        <Redirect push to="/home" />
      )}
      <CssBaseline />
      {video && videoUrl && (firstName || lastName || technician) ? (
        <>
          <div className={classes.customerInfoVideo}>
            <Typography variant="h3" style={{ padding: " 0 0 10px" }}>
              Bonjour,
            </Typography>
            <Typography variant="h4" className={classes.customer}>
              {firstName ? firstName : ""}
              <br />
              {lastName ? lastName : ""}
            </Typography>
          </div>

          {/* <video
            ref={playerRef}
            src={videoUrl}
            className={classes.video}
            autoPlay
            loop
          /> */}
          <video ref={playerRef} className={classes.video} autoPlay loop>
            <source src={videoUrl} type="video/mp4" />
          </video>

          <div className={classes.technicianInfoVideo}>
            {technician ? (
              <>
                <Typography variant="h5" style={{ paddingBottom: 10 }}>
                  Vous êtes pris en charge par :
                </Typography>
                <div style={{ position: "relative" }}>
                  <img
                    className={classes.imageVideo}
                    src={
                      technicianImg
                        ? technicianImg
                        : "images/technician_placholder.jpg"
                    }
                    onError="this.src='images/technician_placholder.jpg'"
                    alt="Technician Profile Img"
                  />
                  <Typography variant="h5" className={classes.technicianVideo}>
                    {technician}
                  </Typography>
                </div>
              </>
            ) : (
              <div style={{ position: "relative", width: 300 }}>
                <Typography variant="body1" style={{ padding: " 0 10px 10px" }}>
                  Installez vous sur le fauteuil un préleveur va vous prendre en
                  charge.
                </Typography>
                <Typography variant="body1" style={{ padding: " 0 10px 5px" }}>
                  Merci d’insérer votre carte vitale dans le lecteur et de
                  préparer votre ordonnance et votre carte de mutuelle.
                </Typography>
                <div className={classes.iconHolderVideo}>
                  <CreditCard className={classes.iconVideo} />
                  <Description className={classes.iconVideo} />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Grid
            item
            xs={6}
            style={{ display: "flex" }}
            className={classes.bgColor}
          >
            <div className={classes.section}>
              <Typography
                variant="h2"
                style={{ padding: " 0 0 50px", width: "25vw" }}
              >
                Bonjour,
              </Typography>
              <Typography variant="h2" className={classes.customer}>
                {firstName ? firstName : ""}
                <br />
                {lastName ? lastName : ""}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} elevation={6} style={{ display: "flex" }}>
            <div className={classes.section}>
              {technician ? (
                <>
                  <Typography variant="h2" style={{ paddingBottom: 25 }}>
                    Vous êtes pris en charge par :
                  </Typography>
                  <div style={{ position: "relative" }}>
                    <img
                      className={classes.image}
                      src={
                        technicianImg
                          ? technicianImg
                          : "images/technician_placholder.jpg"
                      }
                      onError="this.src='images/technician_placholder.jpg'"
                      alt="Technician Profile Img"
                    />
                    <Typography variant="h2" className={classes.technician}>
                      {technician}
                    </Typography>
                  </div>
                </>
              ) : (
                <div style={{ position: "relative" }}>
                  <Typography variant="h3" style={{ padding: " 0 50px 50px" }}>
                    Installez vous sur le fauteuil un préleveur va vous prendre
                    en charge.
                  </Typography>
                  <Typography variant="h3" style={{ padding: " 0 50px 25px" }}>
                    Merci d’insérer votre carte vitale dans le lecteur et de
                    préparer votre ordonnance et votre carte de mutuelle.
                  </Typography>
                  <div className={classes.iconHolder}>
                    <CreditCard className={classes.icon} />
                    <Description className={classes.icon} />
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </>
      )}

      <div
        style={{
          position: "absolute",
          overflow: "hidden",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <svg
          className={classes.waves}
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className={classes.parallax}>
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(41, 121, 255,0.6)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(41, 121, 255,0.4)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="6"
              fill="rgba(41, 121, 255,0.3)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="9"
              fill="rgba(41, 121, 255,0.2)"
            />
          </g>
        </svg>
      </div>
    </Grid>
  );
}
